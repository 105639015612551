.new-sidebar-menu {
  width: $sidebar-width;
  z-index: 10;
  background: #151515;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding-top: 100px;
  box-shadow: $box-shadow;

  @media (max-width: 991.98px) {
    top: 100px;
    padding-top: 0px;
  }

  .navbar-brand-box {
    position: fixed;
    top: 0px;
    z-index: 99;

    .logo {
      display: flex;
      height: 100px;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 991.98px) {
      display: none;
    }
  }

  .list-menu-sidebar {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-top: 10px;

    .list-menu {
      list-style-type: none;
      padding: 0px;
      margin: 0px;

      .list {
        position: relative;

        .list-link {
          padding: 12px 24px 12px 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          font-family: $font-family-sans-serif;
          cursor: pointer;
          color: #a6b0cf;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;

          .icon {
            display: block;
          }

          .list-chevron {
            width: 16px;
            height: 16px;
          }

          svg {
            width: 24px;
            height: 24px;
          }

          &:hover {
            color: $primary;
          }

          &.active {
            color: $primary;
          }
        }

        .list-sub-menu {
          margin-left: 56px;
          list-style-type: none;
          padding: 0px;
          margin-bottom: 0px;

          .list-sub {
            .list-link {
              padding: 12px 0px;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
              font-family: $font-family-sans-serif;
              cursor: pointer;
              color: #a6b0cf;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;

              svg {
                width: 24px;
                height: 24px;

                &.list-chevron {
                  width: 12px;
                  height: 12px;
                }
              }

              &:hover {
                color: $primary;
              }

              &.active {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
}
