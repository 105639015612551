.page-finance {
  .section-filter {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 20px;

    .component-search {
      position: relative;
      grid-column: span 11 / span 11;

      &.full {
        grid-column: span 12 / span 12;
      }

      .input-icon {
        pointer-events: none;
        position: absolute;
        inset: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding-left: 16px;

        .icon {
          width: 24px;
          height: 24px;
          color: "#7B8598";
        }
      }

      .input-search {
        display: block;
        width: 100%;
        border-radius: 4px;
        height: 100%;
        padding: 16px 16px 16px 50px;
        color: #374151;
        border: 1px solid #a4abb8;

        &:focus {
          outline: none;
          border-width: 1px;
          border-color: $primary;
        }

        &::placeholder {
          color: #a5b4cc;
        }
      }
    }

    .component-filter {
      position: relative;
      z-index: 5;

      .btn-filter {
        border: 1px solid #29ccb1;
        padding: 6px 10px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        color: $primary;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        height: 54px;
        max-height: 54px;

        svg {
          width: 24px;
          height: 24px;
        }

        span {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .option-filter {
        background-color: #ffffff;
        border: 1px solid #a4abb8;
        border-radius: 4px;
        position: absolute;
        padding: 6px 10px;
        right: 0px;
        bottom: -128px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 150px;
      }
    }
  }

  .section-table {
    z-index: 0;
    margin-top: 30px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02);

    .table-responsive {
      overflow-x: auto;

      table,
      td,
      th {
        border: 1px solid #b9b9b9;
      }

      table {
        border-collapse: collapse;
        border: 1px solid #b9b9b9;

        thead {
          background-color: #f0f0f0;

          tr {
            th {
              font-weight: 600;
              color: $black;
            }
          }
        }

        tbody {
          tr {
            td {
              background-color: #ffffff;
            }
          }
        }
      }
    }

    .tabel-no-data {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
      width: 100%;
      border-width: 0px 1px 1px 1px;
      border-color: #ccc;
      border-style: solid;
      margin-top: -20px;
    }
  }
}

.page-detail-finance {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;

  .section-content-finance {
    background-color: #ffffff;
    padding: 30px 35px;

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .content-detail {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      .content-detail-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px 20px;
        border-bottom: 1px solid #e8e8e8;

        &.last {
          border-bottom: 0px;
        }

        .items-title {
          font-size: 16px;
          font-weight: 600;
          flex: 1;
        }

        .items {
          font-size: 16px;
          font-weight: 300;
          flex: 1;

          &.primary-color {
            color: $primary;
            font-weight: 700;
          }
        }

        .capitalize {
          text-transform: capitalize;
        }
      }
    }
  }
}

.page-detail-finance {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;

  .section-content-finance {
    background-color: #ffffff;
    padding: 30px 35px;

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .content-detail {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      .content-detail-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px 20px;
        border-bottom: 1px solid #e8e8e8;

        &.last {
          border-bottom: 0px;
        }

        .items-title {
          font-size: 16px;
          font-weight: 600;
          flex: 1;
        }

        .items {
          font-size: 16px;
          font-weight: 300;
          flex: 1;

          &.primary-color {
            color: $primary;
            font-weight: 700;
          }
        }
      }
    }
  }
}
