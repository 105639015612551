//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }

  .text-wrapper {
    overflow-wrap: break-word;
    word-break: break-all;
    text-overflow: initial;
    inline-size: 150px;
    overflow: hidden;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>* {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}


.loading-table-overlay {
  background-color: rgba(240, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 20%;
  z-index: 0;
}