//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    color: $gray-700;
  }
  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons";
    }
  }
}

.breadcrumb-costum {
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  gap: 2px;

  .breadcrumb-costum-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }
}
