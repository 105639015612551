.page-container {
  font-family: $font-family-sans-serif;
  display: flex;
  flex-direction: column;

  .page-content-costume {
    flex: 1;
    overflow-y: auto;
    padding: 40px 32px 60px;

    .section-header {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        font-weight: 600;
        font-size: 24px;
        color: #333333;
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        color: #464a53;
      }
    }

    .section-content {
      padding: 24px;
      margin-top: 42px;
      background-color: #ffffff;
      box-shadow: 11px 10px 21px -5px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 11px 10px 21px -5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 11px 10px 21px -5px rgba(0, 0, 0, 0.2);
    }

    .modal-change-password {
      padding: 40px 30px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: center;
    }

    .page-content-costume-container {
      background-color: #ffffff;
      padding: 20px;

      .title {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.section-table {
  z-index: 0;
  margin-top: 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02);

  .table-responsive {
    overflow-x: auto;

    table,
    td,
    th {
      border: 1px solid #b9b9b9;
    }

    table {
      border-collapse: collapse;
      border: 1px solid #b9b9b9;

      thead {
        background-color: #f0f0f0;

        tr {
          th {
            font-weight: 600;
            color: $black;
          }
        }
      }

      tbody {
        tr {
          td {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .tabel-no-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    width: 100%;
    border-width: 0px 1px 1px 1px;
    border-color: #ccc;
    border-style: solid;
    margin-top: -20px;
  }
}
